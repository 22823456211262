// @ts-nocheck
import Vue from "vue";
import { HTTP } from "@/service/axios";
var errorMsg = {
  data: {
    data: "",
  },
  success: false,
};
Vue.mixin({
  methods: {
    async apiCheckJoin(token, id) {
      //HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
      let res = await HTTP.get(`check/join/ref/${id}`).catch((e) => {
        console.log("API apiCheckJoin catch", e);
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      if (res.data.success || !res.data.success) {
        return res;
      } else {
        errorMsg.data.data = res.data.data;
        return errorMsg;
      }
    },
    async apiExportRefer ( token, id )
    {
      HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
      HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
      let res = await HTTP.get(`export/users/ref/${id}`).catch((e) => {
        console.log("API exportRefer catch", e);
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      if (res.data.success || !res.data.success) {
        return res;
      } else {
        errorMsg.data.data = res.data.data;
        return errorMsg;
      }
    },
    async getUserInRefer ( token, obj )
    {
      HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
      HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
      let res = await HTTP.post("users/ref", obj).catch((e) => {
        console.log("API getUserInRefer catch", e);
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      if (res.data.success || !res.data.success) {
        return res;
      } else {
        errorMsg.data.data = res.data.data;
        return errorMsg;
      }
    },
    async getReferCode(code) {
      let obj = {
        code: code,
      };
      HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
      let res = await HTTP.post("check/refer", obj).catch((e) => {
        console.log("API getReferCode catch", e);
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        }
      });
      if (res.data.success || !res.data.success) {
        return res;
      } else {
        errorMsg.data.data = res.data.data;
        return errorMsg;
      }
    },
    async joinProjectByRefer(token, code) {
      let obj = {
        refId: code,
      };
      HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
      HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
      let res = await HTTP.post("join/ref", obj).catch((e) => {
        console.log("API joinProjectByRefer catch", e);
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      if (res.data.success || !res.data.success) {
        return res;
      } else {
        errorMsg.data.data = res.data.data;
        return errorMsg;
      }
    },
  },
});
