<template>
  <div>
    <h4 class="text-center text-primary font-22" v-if="!edit">
      <u>ขั้นตอนที่ 2</u> กรอกข้อมูลส่วนตัว
    </h4>

    <h3 class="text-center" v-else>แก้ไขข้อมูลส่วนตัว</h3>
    <div class="is-flex js-center ai-center">
      <div
        class="box--profile overflow-hidden el-upload-list--picture-card"
        v-if="
          ruleForm.profileImg &&
          ruleForm.profileImg != 'undefined' &&
          dialogImageUrl == null
        "
      >
        <div class="el-upload--picture-card">
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-delete"
              @click="(dialogImageUrl = null), (ruleForm.profileImg = null)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>

          <div v-if="ruleForm.profileImg && ruleForm.profileImg != undefined">
            <img
              :src="
                ruleForm.profileImg.img !== undefined
                  ? ruleForm.profileImg.img
                  : imgUrl + ruleForm.profileImg
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <el-upload
        v-else
        action="#"
        id="uploadProfile"
        :auto-upload="false"
        list-type="picture-card"
        :on-change="handlePreview"
        disabled
        :on-remove="onRemove"
        :class="{ haveImage: dialogImageUrl != null }"
        :limit="1"
        class="box--profile overflow-hidden el-upload-list--picture-card"
      >
        <div slot="default">
          <span
            class="icon"
            v-if="!ruleForm.profileImg || ruleForm.profileImg == 'undefined'"
          >
            <i class="fas fa-1x fa-camera"></i>
            <p class="text-primary">อัพโหลดรูป</p>
          </span>
          <div v-if="dialogImageUrl">
            <img :src="dialogImageUrl.url" alt="" />
          </div>
          <!-- <div
            v-else-if="
              ruleForm.profileImg && ruleForm.profileImg != 'undefined'
            "
          >
            <img :src="imgUrl + ruleForm.profileImg" alt="" />
          </div> -->
          <!-- <div v-else>
            <img src="/img/logo.e1f9d005.svg" class="mw-100" alt="" />
          </div> -->
        </div>
      </el-upload>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-row :gutter="20">
        <!-- <el-col :span="24" :offset="0">
          <el-form-item label="คำนำหน้าชื่อ" prop="titleName">
            <el-select
              v-model="ruleForm.titleName"
              placeholder="กรุณาเลือกคำนำหน้าชื่อ"
              id="titleName"
              class="w-100"
              :disabled="
                $route.name == 'EditProfile' ||
                (edit && $route.name == 'Signup')
              "
              @change="
                ruleForm.titleName == 4
                  ? (ruleForm.education = 4)
                  : ruleForm.titleName == 1
                  ? (ruleForm.gender = 1)
                  : ruleForm.titleName == 2 || ruleForm.titleName == 3
                  ? (ruleForm.gender = 2)
                  : null
              "
            >
              <el-option
                v-for="data in titleNameOption"
                :label="data.label"
                :value="data.value"
                :key="data.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :md="24" :offset="0">
          <el-form-item label="ชื่อ" prop="name">
            <el-input
              v-model="ruleForm.name"
              id="name"
              :disabled="edit"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="24" :offset="0">
          <el-form-item label="นามสกุล" prop="surname">
            <el-input
              v-model="ruleForm.surname"
              id="surname"
              :disabled="edit"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :md="24" :offset="0">
          <el-form-item label="เพศ" prop="gender">
            <el-radio-group v-model="ruleForm.gender">
              <el-radio
                id="male"
                :label="1"
                :disabled="ruleForm.titleName == 2 || ruleForm.titleName == 3"
                >ชาย</el-radio
              >
              <el-radio id="girl" :label="2" :disabled="ruleForm.titleName == 1"
                >หญิง</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :md="24" :offset="0">
          <el-form-item
            label="อายุ"
            prop="age"
            v-if="(edit && ruleForm.role == 0) || !edit"
          >
            <el-input
              type="age"
              id="age"
              v-model.number="ruleForm.age"
              v-mask="'##'"
            ></el-input>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :md="24" :offset="0">
          <el-form-item
            label="วุฒิการศึกษา"
            prop="education"
            id="education"
            v-if="(edit && ruleForm.role == 0) || !edit"
          >
            <el-select
              v-model="ruleForm.education"
              placeholder="กรุณาเลือกวุฒิการศึกษา"
              class="w-100"
              @change="
                ruleForm.education == 4 ? (ruleForm.titleName = 4) : null
              "
              :disabled="ruleForm.titleName == 4"
            >
              <el-option
                v-for="data in educationOption"
                :label="data.label"
                :value="data.value"
                :key="data.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :md="24" :offset="0">
          <el-form-item
            label="เบอร์โทรศัพท์มือถือ"
            prop="mobilephone"
            class="fix-label"
          >
            <el-input
              id="mobilephone"
              v-mask="'##########'"
              v-model="ruleForm.mobilephone"
              :disabled="edit"
              maxlength="13"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="24" v-if="user != null && user.role == 0">
          <div class="font-18 pd-t-5">
            <el-checkbox
              :disabled="user.role > 0"
              v-model="ruleForm.consent"
              :false-label="0"
              :true-label="1"
              class="mg-r-6"
              id="consent"
            ></el-checkbox>
            ฉันยินยอม
            ฉันได้อ่าน และยอมรับ
            <a :href="termLink" target="_blank">เงื่อนไขการใช้บริการ</a> และ
            <span class="color-primary">
              ที่จะเปิดเผย ชื่อ-นามสกุล และข้อมูลการติดต่อ
              ให้กับหน่วยงานพันธมิตรของ ธสน. ด้านการสนับสนุนทางการเงิน
              เพื่อการติดต่อประชาสัมพันธ์ และ/หรือ เสนอกิจกรรมทางการตลาด
            </span>
          </div>
        </el-col> -->
        <el-col :span="24" :offset="0">
          <el-form-item
            class="text-center mg-t-5"
            v-if="!edit || $route.name == 'Signup'"
          >
            <el-button
              class="wid220"
              type="primary"
              id="next"
              @click="nextStep()"
              >ต่อไป</el-button
            >
          </el-form-item>
          <el-form-item class="text-right" v-else>
            <div class="is-flex js-between ai-center mg-t-3">
              <a
                @click="$router.go(-1)"
                id="back"
                class="text-grey-light font-18"
                >ย้อนกลับ
              </a>
              <!-- <div>
                <el-button
                  class="wid180"
                  type="primary"
                  @click="saveEditdata"
                  :loading="loading"
                  >บันทึก</el-button
                >
              </div> -->
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios";
export default {
  computed: {
    titleNameOption() {
      return this.$store.state.titleName;
    },
    educationOption() {
      return this.$store.state.education;
    },
    token() {
      return this.$store.state.token;
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: {}
    },
    edit: {
      type: Boolean,
      default: false
    },
    role: {
      type: Number,
      default: 0
    }
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("กรุณากรอกเบอร์โทรศัพท์มือถือ"));
      }
      setTimeout(() => {
        let check = value.indexOf(" ") >= 0;
        if (check) {
          callback(new Error("กรุณากรอกตัวเลขโดยไม่เว้นช่องว่าง"));
        } else {
          callback();
        }
      }, 250);
    };
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("กรุณากรอกอายุ"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("กรุณากรอกเฉพาะตัวเลข"));
        } else {
          if (value < 18) {
            callback(new Error("กรุณากรอกอายุตั้งแต่ 18 ปีขึ้นไป"));
          } else {
            callback();
          }
        }
      }, 250);
    };
    return {
      loading: false,
      imgUrl: process.env.VUE_APP_IMAGE_URL,
      dialogImageUrl: null,
      fileList: [],
      rules: {
        titleName: [
          { required: true, message: "กรุณากรอกคำนำหน้าชื่อ", trigger: "blur" }
        ],
        name: [{ required: true, message: "กรุณากรอกชื่อ", trigger: "blur" }],
        surname: [
          { required: true, message: "กรุณากรอกนามสกุล", trigger: "blur" }
        ],
        gender: [{ required: true, message: "กรุณากรอกเพศ", trigger: "blur" }],
        age: [
          { required: true, message: "กรุณากรอกอายุ", trigger: "blur" },
          { validator: checkAge, trigger: "blur" }
        ],
        education: [
          { required: true, message: "กรุณากรอกวุฒิการศึกษา", trigger: "blur" }
        ],
        mobilephone: [
          { validator: validatePass, trigger: "blur" },
          {
            required: true,
            message: "กรุณากรอกเบอร์โทรศัพท์มือถือ",
            trigger: "blur"
          },
          {
            min: 10,
            max: 13,
            message: "กรุณากรอกเบอร์โทรศัพท์มือถือให้ครบ",
            trigger: "blur"
          }
        ]
      },
      oldImage: ""
    };
  },
  watch: {
    "ruleForm.profileImg"() {
      if (
        typeof this.ruleForm.profileImg !== "undefined" &&
        this.ruleForm.profileImg
      ) {
        this.oldImage = this.ruleForm.profileImg;
      }

      if (
        !this.ruleForm.profileImg &&
        this.oldImage &&
        this.$route.name !== "Signup"
      ) {
        this.onRemove();
      }
    }
  },
  methods: {
    onRemove() {
      this.ruleForm.profileImg = null;
      this.dialogImageUrl = null;

      if (this.$route.name !== "Signup") {
        let obj = {
          file: this.oldImage
        };
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        HTTP.post("profile", obj).then(res => {
          //console.log("onRemove", res);
          if (res.data.success) {
            this.$store.commit("SET_USER_OBJECT", {
              key: "profileImg",
              value: null
            });
          }
        });
      }
    },
    handlePreview(file, fileList) {
      //console.log(file);
      if (
        file.raw.type != "image/jpeg" &&
        file.raw.type != "image/jpg" &&
        file.raw.type != "image/png" &&
        file.raw.type != "image/gif"
      ) {
        this.$message.error("รองรับรูปแบบไฟล์ .jpg .jpeg .png .gif");
        let index = fileList.findIndex(a => a.uid == file.uid);
        fileList.splice(index, 1);
      } else if (file.size > 2000000) {
        this.$message.error("ขนาดรูปภาพไม่เกิน 2MB");
        let index = fileList.findIndex(a => a.uid == file.uid);
        fileList.splice(index, 1);
      } else {
        this.ruleForm.profileImg = file;
        this.ruleForm.profileImg.img = URL.createObjectURL(file.raw);
        this.dialogImageUrl = file;

        if (this.$route.name !== "Signup") {
          this.$emit("saveData", false);
        }
      }
    },
    saveEditdata() {
      this.loading = true;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit("saveData");
        } else {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "กรุณากรอกข้อมูลให้ครบ",
            type: "error"
          });
        }
      });
    },
    nextStep() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit("nextStep");
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>
